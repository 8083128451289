/* Copyright 2016 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

* {
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-size: 10px;
}

#errorWrapper {
  position: absolute;
  width: 100%;
  max-width: 500px;
  padding: 16px;
  height: 240px;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000;
}

#errorWrapperContent {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 100%;
  background-color: rgba(255, 85, 85, 1);
  color: white;
  font-size: 2rem;
  font-family: Arial, Helvetica, sans-serif;
  padding: 16px;
  text-align: center;
}
